import { AppFactory } from '@app/app-factory';
import { UserManager } from '@core/models/user-manager';
import { createLogger } from '@common/log';
// import { notifySuccess } from '@app/notification-service';

const log = createLogger('achievements-service');

const achievements = [
  /// videos
  'video:listening-warmup',
  'video:study-mode',
  'video:vocab-review',
  'video:natural-listen',
  'video:recap', // this drives completion of the interstitials

  /// actions
  'action:reviewed-an-item',
  'action:revealed-first-sb',
  'action:play-in-study-mode',
  'action:completed-study',
  'action:play-in-listen-mode',
  'action:completed-listen',
  // 'action:completed-onboarding-interstitials',
  // 'action:chapter-completed',
] as const;

export type AchievementKey = typeof achievements[number];

export class AchievementsService {
  userManager: UserManager;

  constructor() {
    this.userManager = AppFactory.root.userManager;
    // this.loadAchievements();
    // makeObservable(this);
  }

  achieve(key: AchievementKey): boolean {
    if (!achievements.includes(key)) {
      log.error(`Achievement key not found: ${key}`);
      return false;
    }

    if (this.userManager.userData.userSettings.messageIsDismissed(key)) {
      return false;
    }
    this.userManager.userData.userSettings.dismissMessage(key);
    return true;
  }

  didAchieve(keys: AchievementKey[]): boolean {
    const { userSettings } = this.userManager.userData;

    return keys.every(key => userSettings.messageIsDismissed(key));

    // if (keys.length === 1) {
    //   return userSettings.messageIsDismissed(keys[0]);
    // }

    // for (let i = 0; i < keys.length; i++) {
    //   const key = keys[i];
    //   if (userSettings.messageIsDismissed(key) === false) {
    //     return false;
    //   }
    // }

    // return true;
  }

  resetAllAchievements(): void {
    achievements.forEach(key => {
      this.userManager.userData.userSettings.resetMessage(key);
    });
  }

  /// Singleton
  /// ==========
  private static _instance: AchievementsService;

  static get instance() {
    if (!AchievementsService._instance) {
      AchievementsService._instance = new AchievementsService();
    }
    return AchievementsService._instance;
  }
}

export function achieve(key: AchievementKey): void {
  if (AchievementsService.instance.achieve(key)) {
    // notifySuccess('achieved: ' + key);
    log.info('achieved: ' + key);
  }
}

export function didAchieve(keys: AchievementKey[]): boolean {
  return AchievementsService.instance.didAchieve(keys);
}

(window as any).achieve = achieve;
(window as any).didAchieve = didAchieve;
