import { AppFactory } from '@app/app-factory';
import { appConfig } from '@app/env';
import { bugsnagNotify } from '@app/notification-service';
import { Notation } from '@tikka/client/client-aliases';
import {
  extractDomainName,
  formatProgress,
  notEmptyOrNA,
} from '@utils/string-utils';
import { StringToString } from '@utils/util-types';
import { createLogger } from 'app/logger';
import __, { translateWithoutDefault } from 'core/lib/localization';
import {
  millisToMinutes,
  millisToPrettyDuration,
  minutesToPrettyDuration,
} from 'core/lib/pretty-duration';
import { deburr, isEmpty, lowerCase } from 'lodash';
import { computed } from 'mobx';
import moment from 'moment'; // TODO: remove 'moment' usage in favor of dayjs
import { ModelTreeNode, identifier, volatile } from 'ts-state-tree/tst-core';
import { getBaseRoot } from '../app-root';
import { ActivityGuide, ChapterCatalogData } from '../catalog';
import { Credit } from '../catalog/credit';
import { Speaker } from '../catalog/speaker';
import { UnitCatalogData } from '../catalog/unit-catalog-data';
import { Root } from '../root';
import { Assignment } from '../user-manager';
import { ChapterRef, LocationPointer } from '../user-manager/location-pointer';
import { StoryProgress } from '../user-manager/story-progress';
import { StoryManager, fixButcheredUnitSlugs } from './story-manager';
import { Soundbite } from './soundbite';
import { ListeningStats } from '../user-manager/listening-stats';
import { Channel } from './channel';

const log = createLogger('story');

// beware, can't use the __f here because of initialization dependencies
export const SoundbiteFilterValues = {
  allFn: () => __('All stories with Soundbites', 'allStoriesWithSoundbites'),
  withUnstartedFn: () =>
    __('Stories with unplayed Soundbites', 'storiesWithUnplayedSoundbites'),
  withCompletedFn: () =>
    __('Stories with completed Soundbites', 'storiesWithCompletedSoundbites'),
  completedWithUnstartedFn: () =>
    __(
      'Completed stories with unplayed Soundbites',
      'completedStoriesWithUnplayedSoundbites'
    ),
};

export const vocabToReviewFilterLabelFn = () =>
  __('Stories with vocabulary to review', 'storiesWithVocabToReview');

// correlates to VolumeCaliData masala schema
export class Story extends ModelTreeNode {
  static CLASS_NAME = 'Story' as const;

  @identifier
  slug: string = '';
  version: number;
  ingestedAt: string;

  volumeDataUrl: string = null;
  channelSlug: string = null;

  title: string = '';
  tagline: string = '';
  description: string = '';
  weblink: string;
  // seasonNumber: string;
  originalBroadcastDate: string = null; // iso date

  releaseDate: string = null; // iso date
  // trial: boolean = false;

  topics: string[] = [];
  countries: string[] = [];
  ibTags?: string[] = [];
  apTags?: string[] = [];

  topicSlugs: string[] = [];
  countrySlugs: string[] = [];
  ibTagSlugs?: string[] = [];
  apTagSlugs?: string[] = [];

  speakers: Speaker[] = []; // ordered via masala UI
  credits: Credit[] = []; // ordered via client-side logic

  activityGuideData?: ActivityGuide = null;

  // not currently getting properly generated
  // totalDurationMinutes: number = 0; // todo: consider removing this field and only using chapter level sum

  listImageUrl: string; // imageThumbUrl
  themeColor: string = '#8A60AB'; // default guaranteed during ingestion

  units: UnitCatalogData[] = [];

  @volatile
  vocabLookupData: Object = {};

  // JRW: @jfe is a "once" call of the async func which sets an observable more correct?
  @volatile
  isCachedMemoizedState: boolean;

  //
  // old
  //

  // @identifier
  // slug: string = '';

  // units: UnitCatalogData[] = [];

  // volumeData: VolumeCatalogData = snap({});

  static create(snapshot: any = {}) {
    return super.create(Story, snapshot) as Story;
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  get storyManager(): StoryManager {
    return this.root?.storyManager;
  }

  get channel(): Channel {
    return this.storyManager.channel(this.channelSlug);
  }

  get hasChannel(): boolean {
    return !!this.channel;
  }

  get partnerId(): string {
    if (this.storyManager.l2 === 'es') {
      return 'ra'; // assume RA for the entire spanish catalog
    } else {
      return this.channelSlug;
    }
  }

  // get channelSlugs(): string[] {
  //   return [this.channelSlug];
  // }

  // needs to be an array because it's used by the filtering infrastructure
  get channelTitles(): string[] {
    return [this.channel?.title || ''];
  }

  get channelTitle(): string {
    return this.channel?.title || 'Radio Ambulante';
  }

  matchedSlug(slug: string): boolean {
    if (this.legacySlug && this.legacySlug === slug) {
      return true;
    }

    return this.slug === slug;
  }

  get legacySlug(): string {
    if (this.slug === 'moth-if-suit') {
      return 'moth-if-suit-pt';
    }

    if (this.slug === 'moth-yankees') {
      return 'moth-yankees-pt';
    }

    if (this.slug === 'rlab-pt-falling-4') {
      return 'rlab-falling-cats-pt';
    }
    return undefined;
  }

  // // todo: remove usages, but needs to be dealt with carefully because heavily used by spa.
  // // should perhaps wait until after 6.1.0 is releasead, or at least perform first in the spa
  // get catalogData(): UnitCatalogData {
  //   return this.firstUnitData;
  // }

  get firstUnitData(): UnitCatalogData {
    return this.units[0];
  }

  unitDataByNumber(unitNumber: number) {
    return this.units.find(unit => unit.unitNumber === unitNumber);
  }

  unitDataBySlug(slug: string) {
    return this.units.find(unit => unit.slug === slug);
  }

  get unitCount() {
    return this.units.length;
  }

  get multiUnit() {
    return this.unitCount > 1;
  }

  get singleUnit() {
    return !this.multiUnit;
  }

  get lastUnit(): UnitCatalogData {
    return this.units[this.units.length - 1];
  }

  get lastChapter(): ChapterCatalogData {
    return this.lastUnit.chapters[this.lastUnit.chapters.length - 1];
  }

  includesUnit(slug: string): boolean {
    return this.units.some(unit => unit.slug === slug);
  }

  matchesVolumeOrUnitSlug(slug: string): boolean {
    return this.slug === slug || this.includesUnit(slug);
  }

  get hasProgress(): boolean {
    // return !!this.progressMayBeNull;
    // extra complexity is actually needed. empty records are apparently created for the free stories
    const progress = this.progressMayBeNull;
    if (!progress) {
      return false;
    } else {
      return progress.notEmpty;
    }
  }

  // consider user onboarded if they've completed the first chapter
  get onboardingStateSatisfied(): boolean {
    const progress = this.progressMayBeNull;
    if (progress) {
      const furthest = progress.furthestPoint;
      if (furthest.unit > 1 || furthest.chapter > 1) {
        return true;
      }
    }
    return false;
  }

  get onboardingStateClean(): boolean {
    const firstSoundbite = this.firstSoundbite;
    if (firstSoundbite?.completed) {
      return false;
    }
    if (!this.hasProgress) {
      return true;
    }
    const furthest = this.progress.furthestPoint;
    if (
      furthest.unit === 1 &&
      furthest.chapter === 1 &&
      furthest.iteration === 1
    ) {
      return true;
    }
    return false;
  }

  get progress(): StoryProgress {
    const match = this.progressMayBeNull;
    if (match) {
      return match;
    } else {
      log.info(`creating new progress data for ${this.slug}`);
      return this.root.userManager.userData.ensureStoryProgress(this.slug);
      // todo: consider persisting immediately, but probably not needed
    }
  }

  get progressMayBeNull(): StoryProgress {
    if (this.legacySlug) {
      // paranoia since vocab review flow volumeData isn't part of the normal TST root tree
      const { userManager } = AppFactory.root;
      const candidate = userManager.userData.storyProgress(this.legacySlug);
      if (candidate && candidate.notEmpty) {
        return candidate;
      }
    }

    return this.root.userManager.userData.storyProgress(this.slug);
  }

  get inProgress(): boolean {
    return this.progressMayBeNull?.inProgress || false;
  }

  get completed(): boolean {
    return this.progressMayBeNull?.completed || false;
  }

  get unqueued(): boolean {
    return this.hasProgress ? this.progress.unqueued : true;
  }

  get queued(): boolean {
    return this.progressMayBeNull?.queued || false;
  }

  get started(): boolean {
    return this.progressMayBeNull?.started || false;
  }

  get unstarted(): boolean {
    return !this.started;
  }

  get lastListened(): number {
    return this.hasProgress ? this.progress.lastListened : 0;
  }

  // sort order for the dashboard vocab-to-review list widget
  // show completed stories first, then descending by vocab count
  get vocabStatus(): number {
    if (this.completed) {
      return 10000 + this.vocabCount;
    } else {
      return this.vocabCount;
    }
  }

  get isTheOnboardingStory() {
    return this.slug === this.storyManager.onboardingStorySlug;
  }

  updateCurrentPoint(point: LocationPointer) {
    const { userManager } = this.root;
    const progress = userManager.userData.ensureStoryProgress(this.slug);
    progress.updateCurrentPoint(point);
    userManager.persistUserData().catch(bugsnagNotify); // note, persistance runs async
  }

  relisten() {
    const chapter = this.firstChapterOfFirstUnitData;
    chapter.review();
  }

  exitRelisten() {
    this.lastChapter.resumeStudy();
  }

  // get atEndOfStory() {
  //   return this.progress?.currentPoint?.atEndOfStory;
  // }

  // count of chapters across all units
  // unsure if this should really be used or not
  get chapterCount() {
    return this.chapters.length;
  }

  /// Too verbose? Wanted to be super clear
  get firstChapterOfFirstUnitData() {
    return this.firstUnitData?.chapters[0];
  }
  // get currentUnitChapterCount(): number {
  //   return this.progress?.currentUnit?.chapterCount;
  // }

  // get currentUnitIsLast(): boolean {
  //   return this.progress?.currentUnit?.isLastUnit;
  // }

  // async exportVocab() {
  //   if (this.vocabCount) {
  //     await this.root.userManager?.exportVocab(this.slug);
  //   } else {
  //     log.info(`story[${this.slug}.exportVocab - no vocab saved, skipping`);
  //   }
  // }

  async toggleClassroomFavorite() {
    return this.root.userManager.userData.classroom.toggleFavorite(this.slug);
  }

  get searchableText() {
    if (!this.firstUnitData) return '';
    return deburr(
      [
        this.title,
        this.description,
        this.tagline,
        ...this.countryNames,
        ...this.topicNames,
        ...this.ibTags,
        ...this.apTags,
      ]
        .join(' ')
        .toLowerCase()
    );
  }

  get topicNames(): string[] {
    if (isEmpty(this.topicSlugs)) {
      return this.topics;
    } else {
      return this.topicSlugs.map(key =>
        translateWithoutDefault(`tags:topic:${key}`)
      );
    }
  }

  get countryNames(): string[] {
    const { countryTagsEnabled } = this.root;
    if (!countryTagsEnabled) {
      return [];
    }
    if (isEmpty(this.countrySlugs)) {
      return this.countries;
    } else {
      return this.countrySlugs.map(key =>
        translateWithoutDefault(`tags:country:${key}`)
      );
    }
  }

  get apTagNames(): string[] {
    if (isEmpty(this.apTagSlugs)) {
      return this.apTags;
    } else {
      return this.apTagSlugs.map(key =>
        translateWithoutDefault(`tags:ap:${key}`)
      );
    }
  }

  get ibTagNames(): string[] {
    if (isEmpty(this.ibTagSlugs)) {
      return this.ibTags;
    } else {
      return this.ibTagSlugs.map(key =>
        translateWithoutDefault(`tags:ib:${key}`)
      );
    }
  }

  // get topics() {
  //   return this.volumeData.topics;
  // }

  // get countries() {
  //   return this.volumeData.countries;
  // }

  // // todo: should probably remove usages
  // get themes() {
  //   return this.pedagogicalThemes;
  // }

  get pedagogicalThemes() {
    return [...this.apTagNames, ...this.ibTagNames].sort();
  }

  // get apTags() {
  //   return this.volumeData.apTags;
  // }

  // get ibTags() {
  //   return this.volumeData.ibTags;
  // }

  // get allTags() {
  //   return this.volumeData.allTags;
  // }
  get allTags() {
    return [
      ...tagMapper(this.countryNames, 'country', 'countries'),
      ...tagMapper(this.topicNames, 'topic', 'topics'),
      // ...tagMapper(this.apTags, 'ap', 'ap'),
      // ...tagMapper(this.ibTags, 'ib', 'ib'),
    ];
  }

  get allTagsExceptShorts() {
    return this.allTags.filter(tag => tag.label.toLowerCase() !== 'shorts');
  }

  get isShort() {
    return this.allTags.some(tag => tag.label.toLowerCase() === 'shorts');
  }

  get soundbiteStatuses(): string[] {
    const result = [];
    if (this.hasVisibleSoundbites) {
      result.push(SoundbiteFilterValues.allFn());
      if (this.hasUncompletedSoundbites) {
        result.push(SoundbiteFilterValues.withUnstartedFn());
        if (this.completed) {
          result.push(SoundbiteFilterValues.completedWithUnstartedFn());
        }
      }
      if (this.hasCompletedSoundbites) {
        result.push(SoundbiteFilterValues.withCompletedFn());
      }
    }
    return result;
  }

  get vocabFilterStatuses(): string[] {
    if (this.vocabCount) {
      return [vocabToReviewFilterLabelFn()];
    } else {
      return [];
    }
  }

  // get listImageUrl() {
  //   return this.volumeData.imageThumbUrl;
  // }

  // // get bannerImageUrl() {
  // //   return this.volumeData.bannerImageUrl;
  // // }

  // get weblink() {
  //   return this.volumeData.weblink;
  // }

  // todo: remove usages
  get activityGuideUrl() {
    return this.activityGuideData?.resourceUrl;
  }

  get promoAudioUrl() {
    return this.firstChapter?.normalAudioUrl || '';
  }

  get hasWeblink(): boolean {
    return notEmptyOrNA(this.weblink);
  }

  get weblinkDomain(): string {
    return extractDomainName(this.weblink);
  }

  @computed
  get visibleSoundbites(): Soundbite[] {
    if (appConfig.soundbites.disabled) {
      return [];
    }
    const result = this.storyManager.soundbites
      .filter(
        soundbite => soundbite.volumeSlug === this.slug // (all considered visible now) && soundbite.visible
      )
      // sort by chapter position
      .sort((a, b) => a.sortValue - b.sortValue);
    return result;
  }

  get firstSoundbite(): Soundbite {
    return this.visibleSoundbites[0];
  }

  get visibleSoundbiteCount(): number {
    return this.visibleSoundbites.length;
  }

  get hasVisibleSoundbites(): boolean {
    return this.visibleSoundbiteCount > 0;
  }

  get completedSoundbitesCount(): number {
    return this.visibleSoundbites.filter(soundbite => soundbite.completed)
      .length;
  }

  get hasCompletedSoundbites(): boolean {
    return this.visibleSoundbites.some(soundbite => soundbite.completed);
  }

  get hasUncompletedSoundbites(): boolean {
    return this.visibleSoundbites.some(soundbite => !soundbite.completed);
  }

  soundbiteDisplayProgress(omitZero = true): string {
    return formatProgress(
      this.completedSoundbitesCount,
      this.visibleSoundbiteCount,
      omitZero
    );
    // if (omitZero && this.completedSoundbitesCount === 0) {
    //   return this.visibleSoundbiteCount?.toString() || '0';
    // }
    // return `${this.completedSoundbitesCount}/${this.visibleSoundbiteCount}`;
  }

  get allSoundbitesCompleted(): boolean {
    return this.completedSoundbitesCount === this.visibleSoundbiteCount;
  }

  get featuredSoundbite(): Soundbite {
    return this.visibleSoundbites.find(soundbite => soundbite.isFeatured);
  }

  // get trial() {
  //   return this.volumeData.trial;
  // }

  // get version() {
  //   return this.volumeData.version;
  // }

  // for the learn view, we assume there's a single relevant assignment for a story
  // the if user has joined multiple classrooms with the same story assigned
  // then we'll accept the confusing experience of the wrong assignment details
  // being potentially shown
  get joinedClassroomAssignment(): Assignment {
    return this.root.userManager.accountData?.joinedClassroomAssignmentForStory(
      this
    );
  }

  // applied to managed classrooms
  // needed by teacher (classroom) story list view
  get assignCount() {
    const assignmentMap =
      this.root.userManager.accountData?.assignmentMap ?? {};
    if (assignmentMap && assignmentMap[this.slug]) {
      return assignmentMap[this.slug];
    }
    return 0;
  }

  // get unplayed(): boolean {
  //   return this.progress?.unplayed;
  // }

  // get played() {
  //   return this.progress?.played;
  // }

  // get inProgress() {
  //   return this.progress?.inProgress;
  // }

  // // substate of 'completed'
  // get relistening() {
  //   return this.progress?.relistening;
  // }

  // // drives the "continue" CTA label
  // get listening() {
  //   return this.progress?.listening;
  // }

  // get completed() {
  //   return this.progress?.completed;
  // }

  // deprecated
  get progressBar() {
    return this.studyProgressRatio;
  }

  // todo: revisit this
  get minutesRemaining() {
    // const { catalogData, progress } = this;
    // if (!catalogData || !progress) return 0;

    // const { chapter: chapterPosition, millisPlayed } = progress.furthestPoint;
    // const pastMillisPlayed =
    //   this.catalogData.chapters
    //     .slice(0, chapterPosition - 1)
    //     .reduce((sum, chapter) => sum + chapter.durationMillis, 0) +
    //   millisPlayed;

    // const oneMinuteInMillis = 60 * 1000;

    // const minutesRemaining =
    //   (catalogData.durationMinutes * oneMinuteInMillis - pastMillisPlayed) /
    //   oneMinuteInMillis;
    // return Math.round(minutesRemaining);
    return millisToMinutes(this.timeLeftMillis);
  }

  get chapters(): ChapterCatalogData[] {
    return this.units.map(unit => unit.chapters).flat();
  }

  get blobUrls(): string[] {
    const result = [this.volumeDataUrl];
    for (const chapter of this.chapters) {
      result.push(chapter.playerDataUrl);
      result.push(chapter.normalAudioUrl);
    }
    for (const soundbite of this.visibleSoundbites) {
      result.push(soundbite.dataUrl);
      result.push(soundbite.audioUrl);
    }
    return result;
  }

  get shouldCache(): boolean {
    return (
      this.inProgress || this.queued || (this.trial && !this.inProgress)
      // || this.isFeaturedSoundbiteStory
    );
  }

  // get isFeaturedSoundbiteStory(): boolean {
  //   return this.slug === this.storyManager?.featuredSoundbiteStory?.slug;
  // }

  async ensureCacheState() {
    const cached = await this.isCached();
    if (cached !== this.shouldCache) {
      if (this.shouldCache) {
        await this.ensureCached();
      } else {
        await this.removeFromCache();
      }
    }
  }

  async ensureCached() {
    log.info(`${this.slug} - ensureCached`);
    const blobUrls = this.blobUrls;
    const cached = await AppFactory.assetCacher.addAll(blobUrls);
    this.setIsCachedMemoizedState(cached);
  }

  async removeFromCache() {
    log.info(`${this.slug} - removeFromCache`);
    const blobUrls = this.blobUrls;
    for (const url of blobUrls) {
      await AppFactory.assetCacher.remove(url);
    }
    this.setIsCachedMemoizedState(false);
  }

  async isCached() {
    let result = true;
    const blobUrls = this.blobUrls;
    for (const url of blobUrls) {
      const cached = await AppFactory.assetCacher.isCached(url);
      if (!cached) {
        result = false;
      }
    }
    this.setIsCachedMemoizedState(result);
    return result;
  }

  get isCachedMemoized(): boolean {
    log.debug(
      `isCachedMemoized - memoized state: ${String(this.isCachedMemoizedState)}`
    );
    if (this.isCachedMemoizedState === undefined) {
      // asynchronously resolve and trigger rerender on observed value
      this.isCached()
        .then(value => this.setIsCachedMemoizedState(value))
        .catch(bugsnagNotify);
    }

    return this.isCachedMemoizedState;
  }

  setIsCachedMemoizedState(value: boolean) {
    log.debug(`setIsCachedMemoizedState(${String(value)})`);
    this.isCachedMemoizedState = value;
  }

  priorChapterRef(chapterRef: ChapterRef) {
    if (chapterRef.chapter > 1) {
      return { unit: chapterRef.unit, chapter: chapterRef.chapter - 1 };
    } else {
      if (chapterRef.unit > 1) {
        const unit = chapterRef.unit - 1;
        const unitData = this.unitDataByNumber(unit);
        const chapter = unitData.chapterCount;
        return { unit, chapter };
      } else {
        return null;
      }
    }
  }

  nextChapterRef(chapterRef: ChapterRef) {
    const unitData = this.unitDataByNumber(chapterRef.unit);
    if (chapterRef.chapter >= unitData.chapterCount) {
      const nextUnitData = this.unitDataByNumber(chapterRef.unit + 1);
      if (nextUnitData) {
        return { unit: chapterRef.unit + 1, chapter: 1 };
      } else {
        return null;
      }
    } else {
      return { unit: chapterRef.unit, chapter: chapterRef.chapter + 1 };
    }
  }

  chapterForPoint(point: ChapterRef) {
    return this.chapters.find(ch => ch.matchesPoint(point));
  }

  // the number of chapters strictly before the referenced location
  countChaptersBefore(chapterRef: ChapterRef): number {
    return this.chapters.filter(ch => ch.isBefore(chapterRef)).length;
  }

  get firstChapter(): ChapterCatalogData {
    return this.chapterForPoint({ unit: 1, chapter: 1 });
  }

  get durationMillis() {
    //TODO return this.units.reduce((sum, unit) => sum + unit.durationMillis, 0);
    // return this.totalDurationMinutes * 60 * 1000;
    return this.chapters.reduce((sum, ch) => sum + ch.durationMillis, 0);
  }

  get progressMillis(): number {
    return this.chapters.reduce((sum, ch) => sum + ch.progressMillis, 0);
  }

  get timeLeftMillis(): number {
    return this.durationMillis - this.progressMillis;
  }

  get durationMinutes() {
    // return this.totalDurationMinutes;
    return millisToMinutes(this.durationMillis);
  }

  get timeLeftMinutes() {
    return millisToMinutes(this.timeLeftMillis);
  }

  // reflects furthestPoint on progress bars
  get studyProgressRatio(): number {
    return this.progressMillis / this.durationMillis;
  }

  get studyProgressPercentage() {
    return Math.round(this.studyProgressRatio * 100);
  }

  get totalPoints() {
    return this.progressMayBeNull?.listeningStats?.totalPoints;
  }

  get totalListenedInWords() {
    return millisToPrettyDuration(
      this.progressMayBeNull?.listeningStats?.totalMillis
    );
  }

  get vocabCount(): number {
    return this.progressMayBeNull?.vocabCount || 0;
  }

  get vocabCountDescription() {
    return __('%{count} items', 'countItems', {
      count: this.vocabCount,
    });
  }

  get listeningStats(): ListeningStats | null {
    return this.progressMayBeNull?.listeningStats;
  }

  get trial(): boolean {
    return (
      this.isTheOnboardingStory ||
      // hack force in "easter egg" access for our two legacy free stories
      this.slug === 'ra-perro-raro' ||
      this.slug === 'el-show'
    );
  }

  get locked() {
    const { userManager } = this.root;
    const { accountData } = userManager;
    if (userManager.fullAccess) {
      return false;
    }
    const unlockedSlugs = accountData?.unlockedStorySlugs;
    if (unlockedSlugs && unlockedSlugs.includes(this.slug)) {
      return false;
    }
    // if (this.volume?.isUnlocked) {
    //   return false;
    // }
    return !this.trial;
  }

  get vocabViewData() {
    return this.progressMayBeNull?.vocabViewData;
  }

  get showResetStory() {
    return !this.progressMayBeNull?.unplayed;
  }

  get showMarkComplete() {
    return !this.completed && !this.locked;
  }

  get isNew(): boolean {
    const { newThisWeek } = this.root.storyManager;
    return newThisWeek.includes(this);
  }

  get isAvailable(): boolean {
    // grandfather access to el-show to users who have already interacted with it
    if (this.slug === 'el-show') {
      return this.hasProgress;
    }

    //
    // future story filtering not currently needed
    //

    // const { showFutureStories } = this.root?.userManager?.accountData;
    // if (showFutureStories) {
    //   return true; // for internal users
    // } else {
    //   return this.isReleased;
    // }
    return true;
  }

  get isReleased(): boolean {
    const { currentDate } = this.root.storyManager;
    const today = moment(currentDate);
    const storyDate = moment(this.releaseDate);
    return storyDate.isSameOrBefore(today);
  }

  // links to the matching classroom assignment if this story is included in any joined classrooms
  // if story included in multiple classrooms, last one wins
  // (not going to worry about fully handling that edge case for now)
  get assignment(): Assignment {
    let result = null;
    const { accountData } = this.root.userManager;
    accountData.joinedL2Classrooms.forEach(classroom => {
      const match = classroom.assignmentForSlug(this.slug);
      if (match) {
        result = match;
      }
    });
    return result;
  }

  get voices(): Speaker[] {
    return this.speakers.filter(speaker => speaker.includeInVoices);
  }

  get chapterNotes() {
    return this.chapters.map(chapter => chapter.chapterNotes).flat();
  }

  //
  // sort keys
  //

  // todo: confirm with daniel/frank if these flavors need to be distinct or can just be unified
  get durationDescription() {
    return this.buildDurationDescription({ includeChapters: true });
  }

  get simpleDurationDescription() {
    return this.buildDurationDescription({ includeChapters: false });
  }

  get classroomDurationDescription() {
    return this.buildDurationDescription({ includeChapters: true });
  }

  buildDurationDescription({ includeChapters }: { includeChapters: boolean }) {
    const segments: string[] = [];
    // if (this.unitCount > 1) {
    //   segments.push(
    //     __('%{count} parts', 'parts', { count: this.unitCount })
    //   );
    // }

    segments.push(minutesToPrettyDuration(this.durationMinutes));

    if (includeChapters) {
      segments.push(
        __('%{count} chapters', 'chaptersCount', {
          count: this.chapters.length,
        })
      );
    }

    return segments.join(__(', ', 'listSeparator'));
  }

  // should be redundant now with 'durationMinutes' once the legacy unit based discover view is retired
  get sortDurationMinutes() {
    // return this.catalogData.sortDurationMinutes;
    return this.durationMinutes;
  }

  // not sure why compareLocale didn't properly handle sorting accents
  get sortTitle(): string {
    return lowerCase(deburr(this.title));
  }

  // get releaseDate() {
  //   return this.volumeData.releaseDate;
  // }

  // get originalBroadcastDate() {
  //   return this.volumeData.originalBroadcastDate;
  // }

  // get thumbImagePath() {
  //   return this.download?.listImagePath;
  // },

  // get bannerImagePath() {
  //   if (this.volume) {
  //     return this.volume.thumbImagePath;
  //   } else {
  //     return this.download?.bannerImagePath;
  //   }
  // },

  get isClassroomFavorited() {
    return this.root.userManager.userData.classroom.isFavorited(this.slug);
  }

  // // legacy
  // resolveSpeakerData(label: string) {
  //   return this.firstUnitData?.resolveSpeakerData(label);
  // }

  resolveSpeaker(label: string): Speaker {
    const result = this.speakers.find(speaker => speaker.matches(label));
    if (!result) {
      // todo: make this an assert
      // currently happens because of the order of resolving the web mode
      // and initializing the story manager data
      log.warn(`missing speaker data for label: ${label}`);
      return Speaker.create({});
    }
    return result;
  }

  // todo: consider memoizing or performing during ingestion.
  // (but not heavily used)
  get sortedCredits(): Credit[] {
    const result = Credit.sort(this.credits);
    return result;
  }

  // todo: figure out sorting
  get voicesList(): Speaker[] {
    return this.speakers.filter(speaker => speaker.includeInVoices);
  }

  // get themeColor(): string {
  //   return this.themeColor || '#8A60AB'; // tmp default until data filled in
  // }

  // get volumeDataUrl(): string {
  //   return this.volumeData.volumeDataUrl;
  // }

  // JRW: this will only run again when the inputs are changed but will also always trigger change notification
  // in that case because the array will always be a different object, but it could be optimal anyway
  @computed
  get notations(): Notation[] {
    const result: Notation[] = this.units.map(unit => unit.notations).flat();
    // log.debug('notations count: ', result.length);
    return result;
  }

  vocab(slug: string): Notation {
    const notations = this.notations;
    const result = notations.find(notation => notation.id === slug);
    return result;
  }

  async loadVolumeData(): Promise<Story> {
    const result = await this.root.storyManager.loadVolumeDataUrl(
      this.volumeDataUrl
    );
    return result;
  }

  get masalaVolumeDetailUrl(): string {
    return `${appConfig.masalaBaseUrl}/volumes/slug/${this.slug}`;
  }

  // // not sure if this is a good idea to use or not
  // // attempts to fetch story level data for a soundbite and vocab lookup data needed for story detail screen
  // async ensureVolumeDetailData(): Promise<void> {
  //   if (notEmpty(this.firstUnitData?.elements)) {
  //     log.debug(`ensureVolumeDetailData - existing detail data found`);
  //     return;
  //   }

  //   const url = this.volumeDataUrl;
  //   log.debug(`ensureVolumeDetailData - ${url}`);
  //   // try {
  //   const response = await AppFactory.assetCacher.maybeCachedResponse(url);
  //   const data = await response.json();
  //   applySnapshot(this, data); // any unintended consequences of suplanting the entire story?
  //   //   return true;
  //   // } catch (error) {
  //   //   bugsnagNotify(`ensureVolumeDetailData failed for url: ${url}`);
  //   //   bugsnagNotify(error as Error);
  //   //   return false;
  //   // }
  // }

  async fetchUnitVocabMigrationMap(unitSlug0: string): Promise<StringToString> {
    log.debug(`fetchUnitMigrationMap - ${unitSlug0}`);
    const unitSlug = fixButcheredUnitSlugs(unitSlug0);

    const url = this.volumeDataUrl;
    const response = await AppFactory.assetCacher.maybeCachedResponse(url);
    const data = (await response.json()) as Story;
    const unit = data.units.find(
      unit =>
        unit.slug === unitSlug ||
        (unit.volumeSlug === unitSlug && unit.unitNumber === 1)
    );
    if (!unit) {
      const message = `fetchUnitMigrationMap - unit data not found for slug: ${unitSlug}`;
      log.error(message);
      throw Error(message);
    }
    return unit.bogotaVocabMigrationMap;
  }

  // //
  // // volume level bogota vocab data migration - only used if initial unit scoped migration fails
  // //

  // async fetchDataAndMigrateVocabSlugs(vocabSlugs: string[]): Promise<string[]> {
  //   try {
  //     const fullData = await this.root.storyManager.loadVolumeDataUrl(
  //       this.volumeDataUrl
  //     );
  //     return fullData.migrateBogotaVocabSlugs(vocabSlugs);
  //   } catch (error) {
  //     log.error(
  //       `fetchDataAndMigrateVocabSlugs - error fetching data for story: ${this.slug}`
  //     );
  //     bugsnagNotify(error as Error);
  //     if (isNetworkError(error)) {
  //       throw error;
  //     }
  //     return vocabSlugs;
  //   }
  // }

  // migrateBogotaVocabSlugs(vocabSlugs: string[]): string[] {
  //   const result = vocabSlugs.map(slug => this.bogotaToCaliVocabSlug(slug));
  //   return result;
  // }

  // // beware, doesn't properly ensure unique fuzzy match across units, but this volume level migration
  // // is only used if the initial data migration fails due to network issues
  // bogotaToCaliVocabSlug(slug: string) {
  //   if (isBogotaVocabSlug(slug)) {
  //     for (const unit of this.units) {
  //       const candidate = unit.bogotaToCaliVocabSlug(slug);
  //       if (candidate) {
  //         return candidate;
  //       }
  //     }
  //   }
  //   return slug;
  // }
}

export const hasBogotaVocabSlugs = (vocabSlugs: string[]): boolean => {
  if (!vocabSlugs) return false;
  return vocabSlugs.some(slug => isBogotaVocabSlug(slug));
};

export const isBogotaVocabSlug = (slug: string) => {
  return !isEmpty(slug) && !slug.startsWith('NOTATION:');
};

export type TagType = 'topic' | 'country'; // | 'ap' | 'ib';

export type MappedTag = {
  type: TagType; //'topic' | 'country';
  label: string;
  url: string;
};

const tagMapper = (
  tags: string[],
  type: TagType,
  filterKey: string
): MappedTag[] =>
  tags.map(tag => ({
    type,
    label: tag,
    url: `/discover/?${filterKey}[]=${tag}`,
  }));
