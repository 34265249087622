import Dayjs from 'dayjs';
import { sum } from 'lodash';
import { nanoid } from 'nanoid';
// import { isEqual } from 'lodash';
// import deepmerge from 'deepmerge';
// import { deletedDiff, diff } from 'deep-object-diff';

import { createLogger } from 'app/logger';

import __ from 'core/lib/localization';
// import { AssistSettings } from './assist-settings';
import { ListeningLog, ListeningLogKind } from './listening-log';
import { ListeningStats } from './listening-stats';
import { UserSettings } from './user-settings';
import {
  applySnapshot,
  getSnapshot,
  ModelTreeNode,
  snap,
  TSTStringMap,
  volatile,
} from 'ts-state-tree/tst-core';
import { Root } from '../root';
import { StoryProgress /*, StoryState*/ } from './story-progress';
import { getBaseRoot } from '../app-root';
import { ClassroomUserData } from './classroom-user-data';
import { PlayerSettings } from './player-settings';
import {
  millisToMinutes,
  minutesToPrettyDuration,
} from '@core/lib/pretty-duration';
import { computed, runInAction } from 'mobx';
import { VideoGuideUserData } from './video-guide-user-data';
import { dayjsToIsoDate } from '@utils/date-utils';
import {
  // alertWarning,
  alertWarningError,
  bugsnagNotify,
  // notifySuccess,
} from '@app/notification-service';
import { StudentProgress } from './student-progress';
import { track } from '@app/track';
import { decrementDate } from '@utils/date-utils';
// import { notEmpty } from '@utils/conditionals';
import { clearMapWithFalseValues } from '@utils/util';
// import { deepMergeDiff } from '@utils/deep-merge-diff';
// import { greaterLocationPointer } from './location-pointer';
// import { AppFactory } from '@app/app-factory';
//@ts-expect-error
import { version } from '@jw-spa-version';
import { appConfig } from '@app/config';
import { LocaleCode } from '@utils/util-types';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { ActivityLog, ActivityLogKind } from './activity-log';

const logger = createLogger('um:user-data');

// const { calendarCutoffDate } = appConfig.soundbites;
const { legacyCutoffDate } = appConfig.soundbites;

export type StreakPointType = ListeningLogKind | ActivityLogKind;

export type PointLog = ListeningLog | ActivityLog;

export const getStatsFromLogs = (logs: PointLog[]) => {
  // log.debug('getStatsFromLogs', logs);
  return ListeningStats.create(
    logs.reduce(
      (acc, curr) => {
        return {
          // todo: clean up naming dissidence between logs and stats
          millisListened: acc.millisListened + curr.listenedMillis,
          // millisRelistened: acc.millisRelistened + curr.relistenedMillis,
        };
      },
      {
        millisListened: 0,
        // millisRelistened: 0,
      }
    )
  );
};

const dayInitialsFn = () => __('SMTWTFS', 'dayInitials');
const daysAbbreviationsFn = () => dayInitialsFn().split('');

type GraphData = {
  letter: string;
  points: number;
};

function getPointsByDay(
  logs: PointLog[],
  startDate: Dayjs.Dayjs,
  endDate: Dayjs.Dayjs
): GraphData[] {
  let currentDate = Dayjs(startDate);
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(Dayjs(currentDate));
    currentDate = currentDate.add(1, 'days');
  }

  return dates.map(date => ({
    letter: daysAbbreviationsFn()[date.day()],
    points: logs.reduce(
      (acc, log) =>
        Dayjs(log.date).isSame(date, 'day') ? acc + log.points : acc,
      0
    ),
  }));
}

function sumGraphDataPoints(graphData: GraphData[]): number {
  return graphData.reduce((acc, day) => acc + day.points, 0);
}

type StreakShowState = 'HIDDEN' | 'SHOW_INTERSTITIAL' | 'SHOW_INLINE';

// L2.L1 pair used to define a product / catalog
// export type LocalePair = `${LocaleCode}.${LocaleCode}`;

/**
 * UserData
 *
 * conceptual owner of the client-side mutable user state which needs to get synced to/from the server
 * note the 'storyProgress's now live under the storyManager trunk, but get spliced during the
 * sync process.
 */
export class UserData extends ModelTreeNode {
  static CLASS_NAME = 'UserData' as const;

  static create(snapshot: any) {
    return super.create(UserData, snapshot) as UserData;
  }

  // reference convenience when browsing firestore
  _docId: string;
  _userId: string;
  _email: string;
  _name: string;
  _timestamp: string; // iso timestamp used for backup ref
  _appVersion: string; // app version active when data was persisted
  // _appDate: string; // story manager driven date at time of persist

  listeningLogMap: TSTStringMap<ListeningLog> = snap({});
  listeningLogs: ListeningLog[]; // legacy data structure

  // drives vocab review points
  activityLogMap: TSTStringMap<ActivityLog> = snap({});

  storyProgressMap: TSTStringMap<StoryProgress> = snap({});
  storyProgresses: StoryProgress[]; // legacy data to be migrated as encountered

  // dummy reference to include in schema gen.
  // todo: figure out if this should even be a tst model
  @volatile
  listeningStats: ListeningStats = null;

  playerSettings: PlayerSettings = snap({});
  // legacy bogota settings, only looked at during initial import to playerSettings
  // assistSettings: AssistSettings = snap({});

  userSettings: UserSettings = snap({});

  classroom: ClassroomUserData = snap({});

  // soundbite interaction data and business logic
  soundbiteUserData: unknown; //SoundbiteUserData = snap({});

  // value is now date completed. some legacy test/beta data exists with 'true' as the value
  soundbiteCompletions: TSTStringMap<string | boolean> = snap({});

  // helplets interaction data and business logic
  videoGuideUserData: VideoGuideUserData = snap({});

  // weeks since epoch of last chapter_completed event. used to track weekly engagement
  lastChapterCompletionWeek?: number = 0;

  updatedTime: number; // Date.now epoch timestamp of last local persist
  updatedGuid: string; // unique tag per update to make diffs cheap when exactly matched

  // Date.now timestamp when most recent backup was triggered.
  // note, backup data itself will be the previously persisted data
  // lastBackupTriggerTime: number;
  // StoryManager.date when most recent backup triggered
  lastBackupTriggerDate: string;

  // lastBackupAppVersion: string; // tracks app version when last backup performed, so new backup can be immediately after new version deployed

  // last rails server managed sync data
  // not relevant to firestore sync
  // lastSyncedVersion: number = -2; // need an invalid initial number so we can detect once data has been loaded

  // todo: should move up to UserManager
  // when true, turn on links and theme preview from story list over to masala
  masalaAdmin: boolean = false; // TODO: rename and move to user manager

  // when assigned, overrides appConfig catalog mapping
  overrideCatalogSlug: string = '';

  selectedL1: LocaleCode = '' as LocaleCode; // hack needed so changes can be merged in when syncing to firebase

  // assigned once an l2 path has been visited.
  // locks given user/anonymous session into either es/en content
  // selectedL2: LocaleCode = '' as LocaleCode;
  // should default correctly for anonymous, and get overwritten when logging in
  // todo: think about data migration for legacy lupa users
  selectedL2: LocaleCode = ReturnNavState.l2;

  // reflects if an initial destructive import has been performed
  // (from the account screen or automatic new user import)
  // destructiveImportPerformed: boolean = false;

  // will be set to true the first time a player screen is opened while
  // accountData.mailingListPromptNeeded is true, which will then trigger
  // the prompt next time the dashboard is visited
  mailingListPromptEnabled: boolean = false;

  @volatile
  streakShowState: StreakShowState = 'HIDDEN';

  streakPointRecordedDate: string = null;

  streakPointRecordedType: StreakPointType = null;

  // // used for persistence behavior test code
  // testingScratch: string;

  get root(): Root {
    return getBaseRoot(this);
  }

  // async selectLocalePair(localePair: LocalePair /* L2:L1 */): Promise<boolean> {
  //   logger.info(`selectProduct: ${localePair}`);
  //   const [l2, l1] = localePair.split('.');
  //   // todo: validate
  //   const result = await this.selectL2(l2 as LocaleCode, l1 as LocaleCode);
  //   return result;
  // }

  async selectL2(locale: LocaleCode /*, l1?: LocaleCode*/): Promise<boolean> {
    // if (!this.selectedL2) {
    logger.info(`selectL2: ${locale}`);
    // this.selectedL1 = undefined; // should be appropriately initialized after catalogloads
    this.selectedL2 = locale;
    // this.selectedL1 = l1 || ('' as LocaleCode);
    if (this.root.overridenL1) {
      this.root.overrideL1(undefined); // reset any dev override
    }
    await this.root.userManager.persistUserData();
    this.root.userManager.updateRailsL2(locale).catch(bugsnagNotify); // execute async
    return true;
    // } else {
    //   if (locale !== this.selectedL2) {
    //     if (
    //       this.root.userManager.authenticated &&
    //       !this.root.userManager.hasAdminAccess // loosen up for testing
    //     ) {
    //       alertWarningError({ error: Error('ignoring attempt to change l2') });
    //       return false;
    //     } else {
    //       logger.warn(`switching l2: ${locale}`);
    //       return true;
    //     }
    //   }
    // }
  }

  // async selectL1(locale: LocaleCode) {
  //   if (this.selectedL1 !== locale) {
  //     this.selectedL1 = locale;
  //     this.root.userManager.persistUserData().catch(bugsnagNotify); // execute async
  //   }
  //   this.root.applyLocale();
  //   this.root.userManager.updateRailsL1(locale).catch(bugsnagNotify); // execute async
  // }

  // for now assuming only assigned during app-root init via query param
  async selectL1(locale: LocaleCode) {
    if (this.selectedL1 !== locale) {
      this.selectedL1 = locale;
      await this.root.userManager.persistUserData(); //.catch(bugsnagNotify); // execute async
      // await this.root.userManager.updateRailsL1(locale); //.catch(bugsnagNotify); // execute async
    }
  }

  // get l1(): LocaleCode {
  //   return this.selectedL1 || this.root.storyManager.l1;
  // }

  // applyLocale() {
  //   if (this.locale) {
  //   } else {
  //     // todo: honor system default once translations are flushed out
  //     const defaultLocale = this.systemDefaultLocale;
  //     log.info(`system default locale: ${defaultLocale} - ignoring for now`);
  //   }
  // }

  // beware, this doesn't exist for some old data
  // differentiate between freshly initialized snapshot and real user data
  // get isEmpty(): boolean {
  get emptyUpdatedTime(): boolean {
    return !this.updatedTime;
  }

  mirrorReferenceAccountData() {
    const { accountData } = this.root.userManager;
    this._docId = accountData.userDataUuid;
    this._userId = accountData.userId;
    this._email = accountData.email;
    this._name = accountData.name;
  }

  populateTimestampAppVersion() {
    this._timestamp = new Date().toISOString();
    this._appVersion = version;
  }

  get backupNeeded(): boolean {
    if (appConfig.userDataBackups.disabled) {
      return false;
    }

    if (this._appVersion !== version) {
      return true;
    }

    // return !(
    //   this.lastBackupTriggerTime &&
    //   this.lastBackupTriggerTime > Date.now() - MILLIS_PER_DAY
    // );

    return this.lastBackupTriggerDate !== this.root.storyManager.currentDate;
  }

  // // todo: should be able to remove this now
  // // used to patch local data after sync out
  // setLastSyncVersion(version: number) {
  //   this.lastSyncedVersion = version;
  // }

  markUpdated() {
    this.updatedTime = Date.now();
    this.updatedGuid = nanoid(12); // only used to compare with other versions, 12 chars is plenty
  }

  markBackedUp() {
    // this.lastBackupTriggerTime = Date.now();
    this._appVersion = version;
    this.lastBackupTriggerDate = this.root.storyManager.currentDate;
  }

  get updatedTimeIso() {
    return !!this.updatedTime
      ? new Date(this.updatedTime).toISOString()
      : String(this.updatedTime);
  }

  storyProgress(slug: string): StoryProgress {
    // return this.storyProgresses.find(progress => {
    //   return progress.slug === slug;
    // });

    return this.storyProgressMap.get(slug);
  }

  // beware, can't safely iterate over the list values if the loop triggers persistence
  // which will cause new data to flow in from firestore
  get storyProgressList(): StoryProgress[] /*IterableIterator<StoryProgress> doesn't support filter, etc */ {
    return Array.from(this.storyProgressMap.values());
  }

  get storyProgressKeys(): string[] {
    return Array.from(this.storyProgressMap.keys());
  }

  ensureStoryProgress(slug: string): StoryProgress {
    let progress = this.storyProgress(slug);
    if (!progress) {
      progress = StoryProgress.create({ slug });
      // log.info(`creating StoryProgress(${slug}): ${progress.stringify}`);
      // this.storyProgresses.push(progress);
      this.storyProgressMap.set(slug, progress);
    }
    return progress;
  }

  // data to share with teacher for specified assignment
  assignmentProgressData(storySlug: string): StudentProgress {
    const storyProgress = this.storyProgress(storySlug);
    const stats = this.storyListeningStats(storySlug);
    const allTimeStudiedMillis = stats.totalMillis;
    const vocabCount = storyProgress?.vocabCount || 0;
    const result = StudentProgress.create({
      slug: storySlug,
      email: this._email,
      name: this._name,
      storyProgress,
      allTimeStudiedMillis,
      vocabCount,
    });
    return result;
  }

  get listeningLogList(): ListeningLog[] {
    return Array.from(this.listeningLogMap.values());
  }

  addListeningLog({
    storySlug,
    millis,
    date,
  }: {
    storySlug: string;
    millis: number;
    date?: string;
  }) {
    logger.debug(`addListeningLog, millis: ${millis}`);

    // const currentISODate = dayjs().startOf('day').toISOString();
    if (!date) {
      date = this.root.storyManager.currentDate;
    }

    const mapKey = ListeningLog.mapKey(storySlug, date);

    const log = this.listeningLogMap.get(mapKey);

    if (log) {
      log.listenedMillis += millis;
    } else {
      // TODO this should not be done instead change in firestore will eventually apply
      // this is currently still needed because of race condition between the update invoke and the all-data persist
      // will revisit targetted updates once everything else is stable
      this.applyStreakPoint('CHAPTER', date);
      this.listeningLogMap.set(
        mapKey,
        ListeningLog.create({
          date,
          storySlug,
          listenedMillis: millis,
        })
      );

      // // TODO note this is an async operation being called in a non async function
      // AppFactory.firestoreInvoker.updateUserData(`listeningLogMap.${mapKey}`, {
      //   date,
      //   storySlug,
      //   listenedMillis: millis,
      // });
    }
  }

  get activityLogList(): ActivityLog[] {
    return Array.from(this.activityLogMap.values());
  }

  // single list of values used to drive point calculations
  get pointLogList(): PointLog[] {
    const result: PointLog[] = [];
    this.listeningLogList.forEach(log => result.push(log));
    this.activityLogList.forEach(log => result.push(log));
    return result;
  }

  addActivityLog({
    storySlug,
    date,
    kind,
    count,
    points,
  }: {
    storySlug: string;
    date?: string;
    kind: ActivityLogKind;
    count: number;
    points: number;
  }) {
    logger.debug(`addActivityLog, ${points}`);

    if (!date) {
      date = this.root.storyManager.currentDate;
    }

    const mapKey = ActivityLog.mapKey(storySlug, date, kind);

    const log = this.activityLogMap.get(mapKey);

    if (log) {
      log.count += count;
      log.points += points;
    } else {
      // TODO this should not be done instead change in firestore will eventually apply
      // this is currently still needed because of race condition between the update invoke and the all-data persist
      // will revisit targetted updates once everything else is stable
      this.applyStreakPoint(kind, date);
      this.activityLogMap.set(
        mapKey,
        ActivityLog.create({
          date,
          storySlug,
          kind,
          count,
          points,
        })
      );
    }
  }

  trackWeeklyEngagement() {
    const currentWeek = this.root.storyManager.weeksSinceEpoch;
    if (
      !this.lastChapterCompletionWeek ||
      currentWeek > this.lastChapterCompletionWeek
    ) {
      track('player__active_week', { week: currentWeek });

      // assume will be persisted along with other recordProgress change
      this.lastChapterCompletionWeek = currentWeek;
      logger.info(`updating lastChapterCompletionWeek: ${currentWeek}`);
    }
  }

  // // the simple list to map conversions which can be safely rechecked on every cold start for now
  // migrateSimpleSchemaChanges(): boolean {
  //   try {
  //     const userSettingsChanged = this.userSettings.migrateListToMap();
  //     const classroomChanged = this.classroom.migrateListToMap();
  //     // const soundbiteDataChanged = this.soundbiteUserData.migrateListToMap();
  //     return userSettingsChanged || classroomChanged; // || soundbiteDataChanged;
  //   } catch (error) {
  //     // paranoia
  //     alertWarningError({ error, note: 'UserData.migrateSimpleSchemaChanges' });
  //     return false;
  //   }
  // }

  // migrateBogotaAssistSettings() {
  //   this.playerSettings.setPlaybackRate(this.assistSettings.speed);
  //   this.playerSettings.setRedactionMode(this.assistSettings.caliRedactionMode);
  // }

  // async migrateBogotaUserData() {
  //   this.migrateSimpleSchemaChanges();
  //   this.migrateBogotaAssistSettings();
  //   await this.migrateStoryProgresses();
  //   await this.migrateListeningLogs();
  // }

  // async migrateListeningLogs(): Promise<boolean> {
  //   if (!this.listeningLogs || this.listeningLogs.length === 0) {
  //     logger.debug(`migrateListeningLogs - no legacy data`);
  //     return false;
  //   }

  //   logger.info(`migrateStoryProgresses - legacy data found...`);
  //   this.transformListeningLogListToMap();
  //   this.listeningLogs = undefined; // nuke old data once migrated
  //   await this.root.userManager.persistUserData(); // consider not persisting anything until end
  // }

  // // listeningLog(storySlug: string, date: string): ListeningLog {
  // //   return this.listeningLogMap.get(ListeningLog.mapKey(storySlug, date));
  // // }

  // transformListeningLogListToMap() {
  //   if (!this.listeningLogs) return; // paranoia
  //   const { storyManager } = this.root;
  //   // for now nuke current data
  //   // TODO: merge?
  //   runInAction(() => {
  //     applySnapshot(this.listeningLogMap, {});
  //     for (const log of this.listeningLogs) {
  //       const storySlug = storyManager.storySlugForUnitSlug(log.storySlug); // flatten unit logs to story level
  //       const date = log.date.split('T')[0]; // truncate the undesired time
  //       // we no longer care about distinguishing first listen and relisten in the charts or data, so just flatten during migration
  //       const millis = log.listenedMillis + (log.relistenedMillis || 0);
  //       // this will handle the logic to merge listen data for multiple story parts from same day
  //       this.addListeningLog({ storySlug, date, millis });
  //     }
  //     applySnapshot(this.listeningLogs, undefined);
  //   });
  // }

  createRandomListeningLog() {
    const { storyManager, userManager } = this.root;
    const today = Dayjs(storyManager.currentDate).startOf('day');
    const fifteenDaysAgo = Dayjs(today).subtract(15, 'days');

    const dates = [];
    let currentDate = Dayjs(fifteenDaysAgo);
    while (currentDate <= today) {
      dates.push(Dayjs(currentDate));
      currentDate = currentDate.add(1, 'days');
    }

    const getRandomStorySlug = () => {
      const { availableStories } = storyManager;
      const randomIndex = Math.floor(Math.random() * availableStories.length);
      return availableStories[randomIndex].slug;
    };

    dates.forEach(date => {
      const listenedMillis = 6000 + Math.random() * 1000 * 6000;
      // const relistenedMillis = 6000 + Math.random() * 1000 * 6000;
      const isoDate = dayjsToIsoDate(date);

      const slug = getRandomStorySlug();
      this.listeningLogMap.set(
        ListeningLog.mapKey(slug, isoDate),
        ListeningLog.create({
          date: isoDate,
          storySlug: slug,
          listenedMillis,
          // relistenedMillis,
        })
      );
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    userManager.persistUserData().catch(error => alertWarningError({ error }));
  }

  get hasInProgress() {
    return this.storyProgressList.some(progress => progress.inProgress);
  }

  // originally scoped to be true for legacy users who have completed soundbites from before the 9.x release
  get soundbiteCalendarVisible(): boolean {
    return false; // current plan is to fully remove for all users
    // const hasPriorCompletion = Array.from(
    //   this.soundbiteCompletions.values()
    // ).some(value => {
    //   if (value === true) {
    //     return true;
    //   }
    //   if (!value) {
    //     return false;
    //   }
    //   return value < calendarCutoffDate;
    // });
    // return hasPriorCompletion;
  }

  get legacySoundbiteEngagement(): boolean {
    const hasPriorCompletion = Array.from(
      this.soundbiteCompletions.values()
    ).some(value => {
      if (value === true) {
        return true;
      }
      if (!value) {
        return false;
      }
      return value < legacyCutoffDate;
    });
    return hasPriorCompletion;
  }

  get needsSoundbiteOrientation(): boolean {
    return this.completedSoundbitesCount === 0;
  }

  // filters soundbites included in current catalog
  get soundbiteCompletionActiveEntries(): string[][] {
    const { storyManager } = this.root;
    const result = Array.from(this.soundbiteCompletions.entries())
      .filter(([slug, date]) => !!storyManager.soundbite(slug) && !!date)
      .map(([slug, date]) => [slug, date as string]);
    return result;
  }

  // get completedSoundbitesCount(): number {
  //   const result = Array.from(this.soundbiteCompletions.values()).filter(
  //     value => !!value
  //   ).length;
  //   return result;
  // }
  get completedSoundbitesCount(): number {
    const result = this.soundbiteCompletionActiveEntries.length;
    return result;
  }

  // @computed
  get totalMillis() {
    return sum(this.listeningLogList.map(log => log.listenedMillis));
  }

  get totalPoints() {
    return sum(this.pointLogList.map(log => log.points));
  }

  get todayPoints() {
    const today = this.root.storyManager.currentDate;
    return sum(
      this.pointLogList.filter(log => log.date === today).map(log => log.points)
    );
  }

  get sevenDayPoints() {
    const today = this.root.storyManager.currentDate;
    return this.sevenDayRelativePoints(today);
  }

  get previousSevenDayPoints() {
    const sevenDaysAgo = dayjsToIsoDate(
      this.root.storyManager.today.subtract(7, 'days')
    );
    return this.sevenDayRelativePoints(sevenDaysAgo);
  }

  listeningLogsForStory(slug: string): ListeningLog[] {
    return this.listeningLogList.filter(log => log.storySlug === slug);
  }

  pointLogsForStory(slug: string): PointLog[] {
    return this.pointLogList.filter(log => log.storySlug === slug);
  }

  totalPointsForStory(slug: string) {
    return sum(this.pointLogsForStory(slug).map(log => log.points));
  }

  totalMillisForStory(slug: string) {
    return sum(this.listeningLogsForStory(slug).map(log => log.listenedMillis));
  }

  sevenDayRelativePoints(endDate: string) {
    const endDayjs = Dayjs(endDate);
    const sevenDaysPrior = dayjsToIsoDate(endDayjs.subtract(7, 'days'));
    return sum(
      this.pointLogList
        .filter(log => log.date > sevenDaysPrior && log.date <= endDate)
        .map(log => log.points)
    );
  }

  get totalListenedPretty() {
    return minutesToPrettyDuration(millisToMinutes(this.totalMillis));
  }

  storyListeningStats(slug: string): ListeningStats {
    return getStatsFromLogs(this.storyListeningLogs(slug));
  }

  storyListeningLogs(slug: string): ListeningLog[] {
    return this.listeningLogList.filter(log => log.storySlug === slug);
  }

  get statsChartData() {
    const list = this.pointLogList;
    const today = Dayjs(this.root.storyManager.currentDate);

    const filterLogsByDate = (logs: PointLog[], date: Dayjs.Dayjs) => {
      return logs.filter(log => Dayjs(log.date) >= date); // TODO: confirm this still works as desired with truncated date strings
    };

    const sevenDaysAgo = Dayjs(today).subtract(7, 'days');
    const thirteenDaysAgo = Dayjs(today).subtract(13, 'days');
    const dataForTwoWeeks = filterLogsByDate(list, thirteenDaysAgo);
    const dataForLastWeek = filterLogsByDate(dataForTwoWeeks, sevenDaysAgo);

    const graphData = getPointsByDay(dataForTwoWeeks, thirteenDaysAgo, today);

    const highestDayPoints = Math.max(...graphData.map(p => p.points));
    const totalPoints = sumGraphDataPoints(graphData);

    const lastWeekGraphData = graphData.slice(0, 7);
    const thisWeekGraphData = graphData.slice(7, 14);

    return {
      graphData,
      highestDayPoints,
      totalPoints, // 14 day total (not all time)
      thisWeekTotalPoints: sumGraphDataPoints(thisWeekGraphData),
      lastWeekTotalPoints: sumGraphDataPoints(lastWeekGraphData),
      lastSevenStats: getStatsFromLogs(dataForLastWeek),
      allTimeStats: getStatsFromLogs(list),
      allTimePoints: this.totalPoints,
    };
  }

  // data needed for the "Stories" section of the "My Stats" page
  @computed
  get storyStats() {
    return {
      totalListenedPretty: this.totalListenedPretty,
      completedChapters: this.completedChapters,
      completedStories: this.completedStories,
      totalSavedVocabs: this.totalSavedVocabs,
      totalLearnedVocab: this.totalLearnedVocab,
    };
  }

  soundbiteCompleted(slug: string): boolean {
    return !!this.root.userManager.userData.soundbiteCompletions.get(slug);
  }

  soundbiteCompletedDayjs(slug: string): Dayjs.Dayjs | null {
    try {
      let isoDate =
        this.root.userManager.userData.soundbiteCompletions.get(slug);
      if (!isoDate) {
        return null;
      }
      if (isoDate === true) {
        isoDate = '2023-05-15'; // hardwired data migration for legacy test/beta data
      }
      const result = Dayjs(isoDate as string);
      return result;
    } catch (error) {
      bugsnagNotify(error as Error);
      return null;
    }
  }

  recordSoundbiteCompletion(slug: string) {
    logger.info(`recordSoundbiteCompletion - ${slug}`);
    const date = this.root.storyManager.currentDate;
    this.root.userManager.userData.soundbiteCompletions.set(slug, date);

    const mapKey = ListeningLog.mapKey(slug, date, 'SOUNDBITE');

    const log = this.listeningLogMap.get(mapKey);

    if (log) {
      logger.info(`soundbite points already recorded for current day`);
    } else {
      this.applyStreakPoint('SOUNDBITE', date);
      this.listeningLogMap.set(
        mapKey,
        ListeningLog.create({ date, storySlug: slug, kind: 'SOUNDBITE' })
      );
    }
    this.root.userManager.persistUserData().catch(bugsnagNotify); // execute async
  }

  // get soundbiteListeningLogs(): ListeningLog[] {
  //   return this.listeningLogList.filter(log => log.isSoundbite);
  // }

  hasEngagementForDate(date: string): boolean {
    // todo: think about how to better optimize this
    return this.pointLogList.some(log => log.date === date);
  }

  // todo: this can be more efficient once the vocab onboarding video is fully enabled
  // return true if any progress has vocabCount > 0
  get hasDoneVocabReview(): boolean {
    // return this.validProgresses.some(progress => progress.vocabCount > 0);
    return this.activityLogList.some(log => log.kind === 'VOCAB_REVIEW');
  }

  // engagementForDate(date: string): ListeningLog[] {
  //   return this.listeningLogList.filter(log => log.date === date);
  // }

  // get firstPointsOfTheDay(): boolean {
  //   const date = this.root.storyManager.currentDate;
  //   return this.engagementForDate(date)?.length === 1;
  // }

  get currentStreak(): number {
    let date = this.root.storyManager.currentDate;
    if (!this.hasEngagementForDate(date)) {
      date = decrementDate(date);
    }
    let count = 0;
    for (;;) {
      if (!this.hasEngagementForDate(date)) {
        break;
      }
      count++;
      date = decrementDate(date);
    }
    return count;
  }

  // todo: does this warrant optimizing?
  get longestStreak(): number {
    let date = this.root.storyManager.currentDate;
    if (!this.hasEngagementForDate(date)) {
      date = decrementDate(date);
    }
    let longest = 0;
    let count = 0;
    for (;;) {
      if (!this.hasEngagementForDate(date)) {
        longest = Math.max(longest, count);
        count = 0;
      } else {
        count++;
      }
      date = decrementDate(date);
      if (date < '2023-01-01') {
        longest = Math.max(longest, count);
        break;
      }
    }
    return longest;
  }

  // get showStreakInEndcard(): boolean {
  //   const anonymous = this.root?.userManager?.anonymous;
  //   const { currentStreak, firstPointsOfTheDay } = this;
  //   return anonymous && currentStreak > 0 && firstPointsOfTheDay;
  // }

  async resetSoundbiteCompletions(): Promise<void> {
    // this.soundbiteCompletions.clear();
    clearMapWithFalseValues(this.soundbiteCompletions);
    logger.debug(
      `resetSoundbiteCompletions - ${JSON.stringify(
        getSnapshot(this.soundbiteCompletions)
      )}`
    );
    this.root.userManager.persistUserData().catch(bugsnagNotify);
  }

  resetOnboardingStory() {
    const story = this.root.storyManager.onboardingStory;
    if (!story) {
      bugsnagNotify(`resetOnboardingStory - story not found`);
      return;
    }
    const { progress } = story;
    progress.resetStory();
    progress.clearVocabs();
    const soundbiteSlug = story.firstSoundbite?.slug;
    if (soundbiteSlug) {
      this.resetSoundbiteCompletion(soundbiteSlug);
    } else {
      bugsnagNotify(`resetOnboardingStory - first soundbite not found`);
    }
  }

  // assumes external persistence
  resetSoundbiteCompletion(slug: string): void {
    this.soundbiteCompletions.set(slug, null /*date*/);
  }

  // // data needed for the "Soundbites" section of the "My Stats" page
  // @computed
  // get soundbiteStats(): {
  //   currentStreak: number;
  //   longestStreak: number;
  //   totalCompleted: number;
  // } {
  //   return pick(this.soundbiteUserData, [
  //     'currentStreak',
  //     'longestStreak',
  //     'totalCompleted',
  //   ]);
  // }

  // get currentSoundbiteStreak(): number {
  //   return 4; // todo
  // }

  // get longestSoundbiteStreak(): number {
  //   return 7; // todo
  // }

  // get totalSoundbitesCompleted(): number {
  //   return 21; // todo
  // }

  get completedStories(): number {
    return this.validProgresses.reduce(
      (acc, progress) => acc + (progress.completed ? 1 : 0),
      0
    );
  }

  get completedChapters(): number {
    return this.validProgresses.reduce(
      (acc, progress) => acc + progress.completedChapters,
      0
    );
  }

  get totalSavedVocabs(): number {
    return this.validProgresses.reduce(
      (acc, progress) => acc + progress.vocabCount,
      0
    );
  }

  get totalLearnedVocab(): number {
    return this.validProgresses.reduce(
      (acc, progress) => acc + progress.learnedVocabCount,
      0
    );
  }

  async toggleMasalaAdmin() {
    this.masalaAdmin = !this.masalaAdmin;
    await this.root.userManager.persistUserData(); // async
  }

  // async updateImportPerformed(value: boolean) {
  //   this.destructiveImportPerformed = value;
  //   return this.root.userManager.persistUserData();
  // }

  async updateMailingListPromptEnabled(value: boolean) {
    this.mailingListPromptEnabled = value;
    return this.root.userManager.persistUserData();
  }

  // // convert from array to map, remove empty, update status, merge unit progress into volume, migrate vocab slugs
  // async migrateStoryProgresses(): Promise<boolean> {
  //   const hasListData =
  //     !!this.storyProgresses && this.storyProgresses.length > 0;
  //   if (hasListData) {
  //     logger.info(`migrateStoryProgresses - legacy list data found...`);
  //     await this.transformStoryProgressListToMap();
  //     await this.root.userManager.persistUserData();
  //     return true;
  //   } else {
  //     logger.debug(`migrateStoryProgresses - skipping, no legacy list data`);
  //     return false;
  //   }
  // }

  // async transformStoryProgressListToMap() {
  //   if (!this.storyProgresses) return; // paranoia
  //   const { storyManager } = this.root;

  //   // can't use runInAction since we have await's

  //   // nuke data in current UserData instance. potentially merging handled by UserManager.importUserData
  //   applySnapshot(this.storyProgressMap, {});
  //   for (const unitProgress of this.storyProgresses) {
  //     if (unitProgress.notEmpty) {
  //       try {
  //         unitProgress.resolvePointerUnitNumbers();

  //         const story = storyManager.storyForUnitSlug(unitProgress.slug);
  //         if (story) {
  //           // can't use story.progress because that will create in the wrong UserData
  //           const headProgress = this.ensureStoryProgress(story.slug);
  //           headProgress.mergeProgressData(unitProgress);
  //         } else {
  //           logger.warn(
  //             `transformStoryProgressListToMap - story not found for unit slug: ${unitProgress.slug}`
  //           );
  //           this.storyProgressMap.set(unitProgress.slug, unitProgress);
  //         }
  //       } catch (error) {
  //         logger.error(
  //           `transformStoryProgressListToMap - error migrating or unit slug: ${unitProgress.slug}`,
  //           error
  //         );
  //         bugsnagNotify(error as Error);
  //       }
  //     }
  //     // else simply ignore empty progress records in legacy schema
  //   }
  //   // // preserve but isolate unmatched vocabs
  //   // for (const progress of this.storyProgressList) {
  //   //   progress.archiveOrphanedVocabSlugs();
  //   // }
  //   // applySnapshot(this.storyProgresses, undefined); // this didn't seem to work
  //   this.storyProgresses = undefined; // nuke old data after migration
  // }

  // async repairBorkedProgressData() {
  //   try {
  //     track('system__repair_borked_migration_data');
  //     const { storyManager } = this.root;

  //     const toBeRemovedSlugs: string[] = [];
  //     const unmatchedSlugs: string[] = [];
  //     const unresolvedUnitSlugs: string[] = [];
  //     for (const unitProgress of this.borkedProgresses) {
  //       try {
  //         const story = storyManager.storyForVolumeOrUnitSlug(
  //           unitProgress.slug
  //         );
  //         if (story) {
  //           unitProgress.resolvePointerUnitNumbers();
  //           if (story.slug === unitProgress.slug) {
  //             unitProgress.repairBorkedProgressData();
  //           } else {
  //             const headProgress = this.ensureStoryProgress(story.slug);
  //             headProgress.mergeProgressData(unitProgress);
  //             toBeRemovedSlugs.push(unitProgress.slug);
  //             unitProgress.storyState = StoryState.DELETED; // hack to make sure not resurrected
  //           }
  //         } else {
  //           unmatchedSlugs.push(unitProgress.slug);
  //           logger.warn(
  //             `fixBorkedProgressData - unable to match story for unit slug: ${unitProgress.slug} - ignoring`
  //           );
  //           unresolvedUnitSlugs.push(unitProgress.slug);
  //         }
  //       } catch (error) {
  //         logger.error(
  //           `fixBorkedProgressData - error migrating or unit slug: ${unitProgress.slug}`,
  //           error
  //         );
  //         bugsnagNotify(error as Error);
  //       }
  //     }
  //     // map removal didn't work because of the merge behavior during coldstart
  //     // for (const slug of toBeRemovedSlugs) {
  //     //   this.storyProgressMap.delete(slug);
  //     // }
  //     if (unmatchedSlugs.length > 0) {
  //       bugsnagNotify(
  //         `repairBorkedProgressData - unmatched slugs: ${unmatchedSlugs}`
  //       );
  //     }
  //     await this.root.userManager.persistUserData();
  //     if (this.hasEmptyProgresses) {
  //       logger.info(`pruning empty progresses`);
  //       await this.pruneEmptyProgresses();
  //     }
  //     if (unresolvedUnitSlugs.length > 0) {
  //       bugsnagNotify(
  //         `repairBorkedProgressData - unresolved unit slugs: ${unresolvedUnitSlugs}`
  //       );
  //     } else {
  //       // don't notify user when we have orphaned unit data
  //       notifySuccess('All story progress synchronized');
  //     }
  //     await this.root.userManager.persistUserData();
  //   } catch (error) {
  //     logger.error(`repairBorkedProgressData - failed: ${error}`);
  //     bugsnagNotify(error as Error);
  //   }
  // }

  // async migrateAllPendingBogotaVocabs() {
  //   try {
  //     if (!this.hasVocabMigrationPendingProgresses) {
  //       return;
  //     }
  //     track('system__migrate_all_pending_bogota_vocabs');
  //     notifySuccess('Migrating saved vocab data...');
  //     logger.warn('migrateAllPendingBogotaVocabs');
  //     let count = 0;
  //     // beware, looping over the list resulted in silently ignored operations against
  //     // disconnected tst nodes
  //     // @jason please think about how TST might warn in this situation like MST did
  //     // for (const progress of this.storyProgressList) {
  //     // for (const slug of this.storyProgressKeys) {
  //     const slugs = this.vocabMigrationPendingProgresses.map(
  //       progress => progress.slug
  //     );
  //     logger.info(`pending slugs: ${slugs}`);
  //     for (const slug of slugs) {
  //       const progress =
  //         AppFactory.root.userManager.userData.storyProgress(slug);
  //       const success = await progress.migratePendingBogotaVocabs({
  //         persist: true,
  //       });
  //       if (success) {
  //         count++;
  //       }
  //     }
  //     // await AppFactory.root.userManager.persistUserData();
  //     logger.warn(
  //       `migrateAllPendingBogotaVocabs - updated progress records: ${count}`
  //     );
  //     notifySuccess('Migration complete');
  //   } catch (error) {
  //     logger.error(`migrateAllPendingBogotaVocabs - failed: ${error}`);
  //     bugsnagNotify(error as Error);
  //   }
  // }

  // async testLoopedFirestorePersists({
  //   count,
  //   sleepMs,
  // }: {
  //   count: number;
  //   sleepMs: number;
  // }) {
  //   logger.info(`testLoopedFirestorePersists`);
  //   for (let i = 0; i < count; i++) {
  //     this.testingScratch = `i: ${i}, now: ${new Date().toISOString()}`;
  //     await AppFactory.root.userManager.persistUserData();
  //     await sleep(sleepMs);
  //   }
  // }

  async pruneEmptyProgresses(): Promise<number> {
    const list = this.storyProgressList;
    const startingCount = list.length;
    const removalSlugs = list
      .filter(progress => progress.isEmpty)
      .map(progress => progress.slug);
    logger.info(
      `trimEmptyProgresses - original count: ${startingCount}, empty: ${removalSlugs.length}`
    );
    await this.removeProgressSlugs(removalSlugs);
    return removalSlugs.length;
  }

  async pruneOrphanedProgresses() {
    const list = this.storyProgressList;
    const startingCount = list.length;
    const removalSlugs = list
      .filter(progress => !(progress.hasVolumeSlug || progress.hasUnitSlug))
      .map(progress => progress.slug);
    logger.info(
      `trimOrphanedProgresses - original count: ${startingCount}, removal count: ${removalSlugs.length}`
    );
    await this.removeProgressSlugs(removalSlugs);
  }

  async removeProgressSlugs(slugs: string[]) {
    runInAction(() => {
      for (const slug of slugs) {
        this.storyProgressMap.delete(slug);
      }
    });
    await this.root.userManager.persistUserData();
  }

  async resetAllData() {
    logger.info('resetAllData');
    applySnapshot(this, {});
    await this.root.userManager.persistUserData();
  }

  async resetAllProgresses() {
    logger.info('resetAllProgresses');
    // applySnapshot(this.storyProgresses, []);
    applySnapshot(this.storyProgressMap, {});
    await this.root.userManager.persistUserData();
  }

  async resetAllListeningLogs() {
    logger.info('resetAllListeningLogs');
    applySnapshot(this.listeningLogMap, {});
    await this.root.userManager.persistUserData();
  }

  get emptyProgresses() {
    return this.storyProgressList.filter(progress => progress.isEmpty);
  }

  get validProgresses() {
    return this.storyProgressList.filter(progress => progress.valid);
  }

  get orphanedProgresses() {
    return this.storyProgressList.filter(progress => progress.orphaned);
  }

  get borkedProgresses() {
    return this.storyProgressList?.filter(
      progress => progress.hasBorkedMigrationData
    );
  }

  get hasBorkedMigrationData(): boolean {
    return this.borkedMigrationDataCount > 0;
  }

  get borkedMigrationDataCount(): number {
    return this.borkedProgresses?.length || 0;
  }

  get vocabMigrationPendingProgresses() {
    return this.storyProgressList?.filter(
      progress => progress.hasPendingBogotaVocabs
    );
  }

  get nextVocabMigrationPendingProgress(): StoryProgress {
    return this.storyProgressList?.find(
      progress => progress.hasPendingBogotaVocabs
    );
  }

  get hasVocabMigrationPendingProgresses(): boolean {
    return this.vocabMigrationPendingCount > 0;
  }

  get vocabMigrationPendingCount(): number {
    return this.vocabMigrationPendingProgresses?.length || 0;
  }

  get hasEmptyProgresses(): boolean {
    return this.emptyProgressesCount > 0;
  }

  get emptyProgressesCount(): number {
    return this.emptyProgresses?.length || 0;
  }

  get relevantProgresses() {
    return this.storyProgressList.filter(
      progress => progress.valid && progress.notEmpty
    );
  }
  // todo: migrate unit progresses to volume slugs

  get unitProgresses() {
    return this.storyProgressList.filter(progress => progress.hasUnitSlug);
  }

  get mergeNeededProgresses() {
    return this.storyProgressList.filter(progress => progress.needsMerge);
  }

  // get vocabMigrationNeeded(): boolean {
  //   return this.vocabMigrationNeededProgresses.length > 0;
  // }

  // get vocabMigrationNeededProgresses() {
  //   return this.storyProgressList.filter(
  //     progress => progress.needsVocabMigration
  //   );
  // }

  get progressesWithUnexpectedBogotaVocab() {
    return this.storyProgressList.filter(
      progress => progress.hasBogotaVocabSlugs
    );
  }

  // This is where we grant points for the streak
  // we'll also decide here if we should show the streak interstitial, show it inline
  // or not show it at all
  applyStreakPoint(pointType: StreakPointType, date: string) {
    if (date !== this.streakPointRecordedDate) {
      this.streakPointRecordedDate = date;
      this.streakPointRecordedType = pointType;

      if (this.root.userManager.anonymous) {
        // show it inline in the card for anonymous users
        this.streakShowState = 'SHOW_INLINE';
      } else {
        // show the interstitial for anyone else
        this.streakShowState = 'SHOW_INTERSTITIAL';
      }

      // this.startShowingStreakInterstitial();
    }
  }

  public startShowingStreakInterstitial() {
    this.streakShowState = 'SHOW_INTERSTITIAL';
  }

  public stopShowingStreakInterstitial() {
    this.streakShowState = 'HIDDEN';
  }

  get showStreakInterstitial(): boolean {
    if (!this.root.userManager.fullAccess) {
      // never show streak to trial users
      return false;
    }
    return this.streakShowState === 'SHOW_INTERSTITIAL';
  }

  // given an already normalized/migrated UserData structure, merge into current tree
  // will keep greater of listening logs that share the same date/story
  // will use the later of progress pointers and merge selected vocab

  // attempted to process raw snapshot data, but bowels of the merge logic needs full TST models
  // async mergeInProgressData({
  //   listeningLogMap,
  //   storyProgressMap,
  // }: {
  //   listeningLogMap: { [index: string]: ListeningLog }; // TSTStringMap<ListeningLog>;
  //   storyProgressMap: { [index: string]: StoryProgress }; //TSTStringMap<StoryProgress>;
  // }) {

  // async mergeInProgressData(sourceData: UserData) {
  //   logger.info(`mergeInProgressData`);

  //   runInAction(() => {
  //     logger.info(`log count: ${sourceData.listeningLogMap.size}`);
  //     for (const log of sourceData.listeningLogList) {
  //       const existing = this.listeningLogMap.get(log.mapKey);
  //       if (existing && existing.listenedMillis >= log.listenedMillis) {
  //         logger.debug(
  //           `${log.mapKey}, ${log.listenedMillis} <= ${existing.listenedMillis} - preserving`
  //         );
  //       } else {
  //         if (existing) {
  //           logger.debug(
  //             `${log.mapKey}, ${log.listenedMillis} > ${existing.listenedMillis} - replacing `
  //           );
  //         } else {
  //           logger.debug(`${log.mapKey}, ${log.listenedMillis} - adding`);
  //         }
  //         this.listeningLogMap.set(log.mapKey, log);
  //       }
  //     }

  //     logger.info(`progress count: ${sourceData.storyProgressMap.size}`);
  //     for (const progress of sourceData.storyProgressList) {
  //       const existing = this.storyProgressMap.get(progress.slug);
  //       if (existing) {
  //         logger.debug(`merging into existing: ${progress.slug}`);
  //         existing.mergeProgressData(progress);
  //       } else {
  //         logger.debug(`ading new: ${progress.slug}`);
  //         this.storyProgressMap.set(progress.slug, progress);
  //       }
  //     }
  //   });
  // }

  // // merges in remote data during either cold start/focus/auth or just before persisting
  // // report unexpected divergence depending upon operation.
  // // note, 'persisting' mode not currently used
  // mergeInFetchedData(
  //   remoteData: UserDataSnapshot,
  //   { operation }: { operation: 'retrieving' | 'persisting' }
  // ): boolean {
  //   const localData = this.snapshot as UserDataSnapshot;
  //   const [presumedOlder, presumedNewer] =
  //     operation === 'retrieving'
  //       ? [localData, remoteData]
  //       : [remoteData, localData];
  //   const mergedData = mergeUserData(presumedOlder, presumedNewer);
  //   const divergence = checkForDivergence(mergedData, remoteData, {
  //     operation,
  //   });

  //   if (divergence || operation === 'retrieving') {
  //     applySnapshot(this, mergedData);
  //   } else {
  //     // don't both applying snapshot during normal persist
  //   }
  //   return divergence;
  // }
}

// export const sanitizedDiff = (
//   localData: UserDataSnapshot,
//   baseData: UserDataSnapshot
// ): object => {
//   // strip 'undefined' props so that diff data will be useful
//   // todo: remove once getSnapshot automatically omit's undefined props
//   const sanitizedBase = JSON.parse(JSON.stringify(baseData));
//   const sanitizedLocal = JSON.parse(JSON.stringify(localData)); // not sure if needed
//   // const divergence = !isEqual(localData, sanitizedBase);
//   // if (divergence) {
//   // const result = diff(sanitizedBase, sanitizedLocal);
//   // const deletedDelta = deletedDiff(sanitizedBase, sanitizedLocal);
//   // if (notEmpty(deletedDelta)) {
//   //   const message = `unexpected deletedDiff: ${JSON.stringify(
//   //     deletedDelta
//   //   )} - aborting merge`;
//   //   bugsnagNotify(message);
//   //   alertWarning(message);
//   //   return null;
//   // }

//   const [result, warnings] = deepMergeDiff(sanitizedBase, sanitizedLocal);
//   if (notEmpty(warnings)) {
//     const message = `merge diff warnings: ${JSON.stringify(warnings)}`;
//     bugsnagNotify(message);
//     alertWarning(message);
//   }

//   return result;
// };

// placeholder until we can somehow generate proper snapshot typing
export type UserDataSnapshot = UserData;

// // not currently used
// export const mergeUserData = (
//   presumedOlder: UserDataSnapshot,
//   presumedNewer: UserDataSnapshot
// ): UserDataSnapshot => {
//   presumedOlder = presumedOlder || ({} as UserDataSnapshot);
//   presumedNewer = presumedNewer || ({} as UserDataSnapshot);

//   var baseData: UserDataSnapshot;
//   var newerData: UserDataSnapshot;

//   logger.debug(
//     `mergeUserData - presumedOlder.time: ${String(
//       presumedOlder.updatedTime
//     )}, presumedNewer.time: ${String(presumedNewer.updatedTime)}`
//   );
//   // logger.debug(
//   //   `mergeUserData - presumedOlder.time: ${new Date(
//   //     presumedOlder.updatedTime
//   //   ).toISOString()}, presumedNewer.time: ${new Date(
//   //     presumedNewer.updatedTime
//   //   ).toISOString()}`
//   // );
//   // honor timestamp during merge if differs from presumption
//   if ((presumedNewer.updatedTime || 2) >= (presumedOlder.updatedTime || 1)) {
//     baseData = presumedOlder;
//     newerData = presumedNewer;
//   } else {
//     logger.warn(
//       `mergeUserData - unexpected timestamp seniority: presumedOlder: ${String(
//         presumedOlder.updatedTime
//       )}, presumedNewer: ${String(presumedNewer.updatedTime)}`
//     );
//     baseData = presumedNewer;
//     newerData = presumedOlder;
//   }
//   const mergedSnapshot = deepmerge(baseData, newerData, {
//     // overwrite behavior needed for the orphaned and pending vocabs which are
//     // still preserved as arrays.
//     // (without this option i believe those arrays would compound with each merge)
//     arrayMerge: overwriteMerge,
//     // note, we can potentially handle storyProgress location pointer collisions
//     // more intelligently with the 'customMerge' option:
//     //   https://github.com/TehShrike/deepmerge#custommerge
//     customMerge: customDeepmerge,
//   }) as UserDataSnapshot;
//   // applySnapshot(this.userData, mergedSnapshot);
//   return mergedSnapshot;
// };

// export const checkForDivergence = (
//   mergedData: UserDataSnapshot,
//   presumedNewerData: UserDataSnapshot,
//   { operation }: { operation: string }
// ): boolean => {
//   // strip 'undefined' props so that diff data will be useful
//   // todo: remove once getSnapshot automatically omit's undefined props
//   const sanitizedMerged = JSON.parse(JSON.stringify(mergedData));
//   const divergence = !isEqual(presumedNewerData, sanitizedMerged);
//   if (divergence) {
//     const diffDebug = diff(presumedNewerData, sanitizedMerged);
//     logger.info(
//       `${operation} - divergence detected: ${JSON.stringify(diffDebug)}`
//     );
//     bugsnagNotify(`warning: ${operation} - divergence detected`);
//   }
//   return divergence;
// };

// function greaterValue(a: any, b: any) {
//   return a > b ? a : b;
// }

// function customDeepmerge(key: string) {
//   if (key === 'currentPoint' || key === 'furthestPoint') {
//     return greaterLocationPointer;
//   }
//   if (
//     key === 'lastListened' ||
//     key === 'lastRecordedSessionCounter' ||
//     key === 'showVocabListExportOption'
//   ) {
//     return greaterValue;
//   }
// }

// // deepmerge strategy appropriate for our UserData merge
// // https://github.com/TehShrike/deepmerge#arraymerge-example-overwrite-target-array
// const overwriteMerge = (
//   destinationArray: any[],
//   sourceArray: any[],
//   options: any
// ) => sourceArray;

// @jason: it wasn't clear to me how specifically you envisioned to this to be used
// export function mergeUserDataSnapshots(a: any, b: any): any {
//   // if deep property value is in a and b takes from b
//   return deepmerge(a, b);
// }

//
// attic
//

// /*0 and -1 magic values will be assigned to this variable as follows,
//    0: user accept the request to review
//   -1: user decline the  request to review
//   see the spec for more details :
//   https://jiveworld.slite.com/app/channels/ptvqrYJErL/notes/ODyxK9uJfe
//   */
// numCompletedForReviewCta: number = INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA;

// lastAttemptedPurchase: AttemptedPurchase = null;
// lastAttemptedPendingPurchase: AttemptedPurchase = null;
// lastViewedFeaturedReleaseReleaseDate: string = null;

//
// mostly old native support
//

// setVolumeCurrentUnitNumber(slug: string, number: number) {
//   logger.debug(`setVolumeCurrentUnitNumber(${slug}, ${number})`);
//   return this.currentUnits.set(slug, number);
// }

// postponedReviewCta() {
//   this.numCompletedForReviewCta *= 2;
// }

// acceptedReviewCta() {
//   this.numCompletedForReviewCta = 0;
// }

// declinedReviewCta() {
//   this.numCompletedForReviewCta = -1;
// }

// setLastAttemptedPurchase({
//   purchaseType,
//   contentSlug,
// }: {
//   purchaseType: string;
//   contentSlug: string;
// }) {
//   this.lastAttemptedPurchase = AttemptedPurchase.create({
//     purchaseType,
//     contentSlug,
//   });
// }

// clearLastAttemptedPurchase() {
//   this.lastAttemptedPurchase = null;
// }

// setLastAttemptedPendingPurchase({
//   purchaseType,
//   contentSlug,
// }: {
//   purchaseType: string;
//   contentSlug: string;
// }) {
//   this.lastAttemptedPendingPurchase = AttemptedPurchase.create({
//     purchaseType,
//     contentSlug,
//   });
// }

// clearLastAttemptedPendingPurchase() {
//   this.lastAttemptedPendingPurchase = null;
// }

// setLastViewedFeaturedRelease(featuredRelease: StoryCollection) {
//   if (
//     featuredRelease &&
//     featuredRelease.releaseDate !== this.lastViewedFeaturedReleaseReleaseDate
//   ) {
//     this.lastViewedFeaturedReleaseReleaseDate = featuredRelease.releaseDate;
//   }
// }

// clearLastViewedFeaturedRelease() {
//   this.lastViewedFeaturedReleaseReleaseDate = null;
// }

// get showReviewCta(): boolean {
//   const { storyManager } = this.root;
//   if (!storyManager) return false;
//   const numCompletedStories = storyManager.completed.length;
//   return (
//     this.numCompletedForReviewCta > 0 &&
//     numCompletedStories >= this.numCompletedForReviewCta
//   );
// }

// get showReviewCtaMenuItem(): boolean {
//   const { userManager, storyManager } = this.root;
//   if (!userManager || !storyManager) return false;
//   const numCompletedStories = storyManager.completed.length;
//   const hasFullAccess = userManager.accountData.fullAccess;
//   return (
//     hasFullAccess &&
//     numCompletedStories >= INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA
//   );
// }

// get featuredReleaseViewed(): boolean {
//   const { storyManager } = this.root;
//   if (!storyManager) return false;
//   const currentFeaturedRelease = storyManager.latestCollection;
//   const hasViewed =
//     currentFeaturedRelease &&
//     currentFeaturedRelease.releaseDate ===
//       this.lastViewedFeaturedReleaseReleaseDate;
//   return hasViewed;
// }
