import * as React from 'react';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { ElementNode } from '@tikka/client/client-aliases';
import { observer } from 'mobx-react';
import { Sentence as SentenceElement } from '@tikka/client/client-aliases';
import { StyledSentence } from 'player/views/elements-presentations/review-sentence-presentation';
import { usePlayerModel } from 'player/views/player-model-context';
import { Word } from './word-element';
import { useLineReconciler } from '../player-ui-model-setup';
import { bugsnagNotify } from '@app/notification-service';
import { SentenceId } from '@tikka/basic-types';
import { computed } from 'mobx';
import { styled } from '@naan/stitches.config';
import { ChapterReviewModel } from 'vocab-review/chapter-review-model';
import { ReviewSentenceButtons } from 'study/views/notations/review-notations-list/review-sentence-buttons';
import classNames from 'classnames';
// import { useReaction } from '@common/hooks/use-reaction';
// import { useReviewModel } from 'vocab-review/review-placeholder-model';

const SentenceSlideContainer = styled('div', {
  display: 'flex',
  height: '100%',
  padding: '8px',
  opacity: 0,
  transition: 'opacity 0.3s',
  overflow: 'auto',

  variants: {
    current: {
      true: {
        opacity: 1,
      },
    },
  },
});

const ContentWrapper = styled('div', {
  margin: 'auto 0',
  width: '100%',
});

export const Sentence = observer(({ node }: { node: ElementNode }) => {
  const model = usePlayerModel() as ChapterReviewModel;
  const reconciler = useLineReconciler();
  if (!reconciler) {
    bugsnagNotify('Sentence - missing reconciler');
    return null;
  }

  const { children } = node;
  const sentenceId = node.element.id as SentenceId;
  const sentenceElement = node.element as SentenceElement;

  const redactionMode = React.useCallback(
    (wordNode: ElementNode) => () => {
      return model.getWordRedactionMode(wordNode);
    },
    [model]
  );

  const handleUnredaction = React.useCallback(() => {
    model.showOtherWords();
  }, [model]);

  let className = reconciler.getJoinedMembershipStringForElement(sentenceId);

  const finishedPlaying = computed(() =>
    model.sentenceFinishedPlaying(sentenceElement)
  ).get();

  const elementDomId = elementIdToDomId(null, sentenceId);

  return (
    <SentenceSlideContainer
      current={model.isCurrentSentence(node.element as SentenceElement)}
    >
      <ContentWrapper>
        <StyledSentence
          id={elementDomId}
          className={classNames(className, {
            'is-revealed': model.isCurrentSentenceRevealed,
          })}
          onUnredact={handleUnredaction}
          translation={sentenceElement.translation}
          sentenceId={sentenceId as SentenceId}
        >
          {children.map((node: ElementNode, index: number) => {
            const isVocab = model.isWordAVocab(node);
            const isHighlighted = model.isWordHighlighted(node);

            return (
              <Word
                key={node.element.id}
                node={node}
                redaction={redactionMode(node)}
                isVocab={isVocab}
                isHighlighted={isHighlighted}
              />
            );
          })}
        </StyledSentence>
        <ReviewSentenceButtons finishedPlaying={finishedPlaying} />
      </ContentWrapper>
    </SentenceSlideContainer>
  );
});
