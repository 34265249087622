import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { appConfig } from 'app/env';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';
// import { OnboardingService } from '@app/onboarding/onboarding-service';
import { BlockingInterstitial } from '@app/onboarding/blocking-interstitial';

import { StoryDetailScreen } from './story-detail-screen';
import { createLogger } from '@common/log';
import { Story } from '@core/models/story-manager';
import { ListeningWarmupInterstitial } from './onboarding/listening-warmup-interstitial';
import { didAchieve } from '@app/onboarding/achievements';
import { learnHomePath, storySoundbitePath } from 'components/nav/path-helpers';
import { StudyInterstitial } from './onboarding/study-interstitial';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { PlayerMode } from '@common/misc-types';
// import { Text } from '@naan/primitives/text';
import { NaturalListenInterstitial } from './onboarding/natural-listen-interstitial';
import { RecapInterstitial } from './onboarding/recap-interstitial';
import { useInterstitialMigration } from './onboarding/useInterstitialMigration';
import { BeforeAuthInterstitial } from './onboarding/before-auth-interstitial';
import { preloadImages } from 'lib/preload-image';
import { getPosterUrl } from 'components/ui/video-player/player-view-controller';
import { hideBottomTabBar, showBottomTabBar } from 'components/global-layout';
import { StoryNavState } from './story-nav-state';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('story-detail-or-interstial');

const { disableAuthInterstitial } = appConfig;

// const log = createLogger('story-detail-or-interstial');

// const useOnboardingEffects = (story: Story | undefined) => {
//   const { storyManager } = AppFactory.root;

//   React.useEffect(() => {
//     log.debug('onboarding useEffect');
//     if (!story) {
//       return; // possible when bad slug presented and not-found screen shown
//     }
//     const onboardingService = OnboardingService.instance;
//     const onboardingStory = storyManager.onboardingStory;
//     if (story.slug === onboardingStory?.slug) {
//       if (onboardingService.isDismissed('onboardingComplete')) {
//         return;
//       }
//       if (!onboardingService.isDismissed('onboardingInitiated')) {
//         // automatically consider complete if story complete
//         // if (story.progress.completed) {
//         //   log.info('onboarding story already complete - skipping flow');
//         // automatically consider complete if first chapter complete
//         if (onboardingStory.onboardingStateSatisfied) {
//           log.info('onboarding story already complete - skipping flow');
//           onboardingService.dismiss('onboardingComplete');
//           return;
//         }
//         if (story.progress.completed) {
//           log.info('onboarding story already complete - skipping flow');
//           onboardingService.dismiss('onboardingComplete');
//           return;
//         }
//         // first visit after new onboarding flow deployed
//         if (!onboardingStory.onboardingStateClean) {
//           // automatically reset the state if in-progress
//           log.info('onboarding story in-progress - resetting state');
//           onboardingService.resetOnboardingState();
//         }
//         log.info('onboarding initiated ');
//         onboardingService.dismiss('onboardingInitiated');
//       }
//     }
//   }, [story, storyManager]);
// };

const OnboardingInterstitial = ({
  story,
  forcePass,
}: {
  story: Story;
  forcePass: () => void;
}) => {
  // debugger;
  // const { userManager, storyManager } = AppFactory.root;
  const navigate = useNavigate();
  const navigateToStudy = useNavigateToStudy();

  const { userManager } = AppFactory.root;

  if (!didAchieve(['video:listening-warmup', 'action:revealed-first-sb'])) {
    return (
      <ListeningWarmupInterstitial
        onDismiss={() => {
          // debugger;
          navigate(learnHomePath());
        }}
        onForcePass={() => {
          story.progress.beginStudying();
          const soundbite = story.firstChapter.firstSoundbite;
          const path = storySoundbitePath(story.slug, soundbite?.slug);
          navigate(path);
        }}
      />
    );
  }

  if (!userManager.loggedInAndReady && !disableAuthInterstitial) {
    return <BeforeAuthInterstitial story={story} />;
  }

  if (!didAchieve(['video:study-mode', 'action:play-in-study-mode'])) {
    return (
      <StudyInterstitial
        onDismiss={() => {
          navigate(learnHomePath());
        }}
        onForcePass={() => {
          navigateToStudy(story.firstChapter, PlayerMode.STUDY, 0, true);
        }}
      />
    );
  }

  // if (story.progress.furthestPoint.iteration === 1) {
  if (!didAchieve(['action:completed-study'])) {
    return <ResumeStudy story={story} mode={PlayerMode.STUDY} />;
  }

  if (!didAchieve(['video:natural-listen', 'action:play-in-listen-mode'])) {
    return (
      <NaturalListenInterstitial
        onDismiss={() => {
          navigate(learnHomePath());
        }}
        onForcePass={() => {
          navigateToStudy(
            story.firstChapter,
            PlayerMode.FLUENT_LISTEN,
            0,
            true
          );
        }}
      />
    );
  }

  // if (story.progress.furthestPoint.iteration === 2) {
  if (!didAchieve(['action:completed-listen'])) {
    return <ResumeStudy story={story} mode={PlayerMode.FLUENT_LISTEN} />;
  }

  if (!didAchieve(['video:recap'])) {
    return (
      <RecapInterstitial
        onDismiss={() => {
          navigate(learnHomePath());
        }}
        onForcePass={() => {
          forcePass();
        }}
      />
    );
  }

  // this is what happens if we have more conditions than interstitials
  // probably we should just render the story detail screen
  // return (
  //   <div>
  //     <Text color="red-500" textStyle="medium-handwritten-heading">
  //       If you're seeing this, something went very bad
  //     </Text>
  //   </div>
  // );
  bugsnagNotify('Unepxected onboarding state');
  return <StoryDetailScreen story={story} />;
};

const ResumeStudy = ({
  story,
  mode,
}: {
  story: Story;
  mode: PlayerMode;
}): JSX.Element => {
  const navigateToStudy = useNavigateToStudy();

  React.useEffect(() => {
    const chapter = story.firstChapter;
    navigateToStudy(
      chapter,
      mode,
      chapter.currentMillis,
      false /* viaDetail */
    );
  }, [story, mode, navigateToStudy]);

  return null;
};

// todo: understand why this gets rerendered at the end of the study video (but not warmup video)

const MaybeInterstitialScreen = observer(({ story }: { story: Story }) => {
  // debugger;
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const [forcePass, setForcePass] = React.useState(false);

  useInterstitialMigration({ story });

  React.useEffect(() => {
    log.info('will preload images');
    const images = [
      'listening-warmup',
      'study-mode',
      'natural-listening',
      'recap',
      'vocab-review',
    ]
      .map(s => s + '-poster.svg')
      .map(getPosterUrl);

    void preloadImages(images);
  }, []);

  const bypassInterstitialFn = () =>
    forcePass || OnboardingService.instance.isInterstitialOnboardingComplete;

  // didAchieve([
  //   // listening warmup conditions
  //   'video:listening-warmup',
  //   'action:revealed-first-sb',
  //   // study mode conditions
  //   'video:study-mode',
  //   'action:play-in-study-mode',
  //   // natural listen conditions
  //   'video:natural-listen',
  //   'action:play-in-listen-mode',
  //   //recap
  //   'video:recap',
  //   // 'action:chapter-completed', // no longer relevant
  // ]) || forcePass;

  return (
    <BlockingInterstitial
      renderInterstitial={() => (
        <OnboardingInterstitial
          story={story}
          forcePass={() => setForcePass(true)}
        />
      )}
      passConditionFn={bypassInterstitialFn}
    >
      <StoryDetailScreen story={story} />
    </BlockingInterstitial>
  );
});

// todo: understand why this gets rerendered at the end of the study video (but not warmup video)
export const StoryDetailOrInterstitialScreen = observer(() => {
  // debugger;
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);

  React.useEffect(() => {
    StoryNavState.externalLanding = false;
    hideBottomTabBar();

    //OnboardingService.instance.onStoryDetail(story);

    return () => {
      StoryNavState.externalLanding = true;
      showBottomTabBar();
    };
  }, [story]);

  useEnableUpdatePrompt();

  if (story.slug !== storyManager.onboardingStorySlug) {
    return <StoryDetailScreen story={story} />;
  }

  return <MaybeInterstitialScreen story={story} />;
});
