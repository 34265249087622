import { pickLocalizedValue } from '@core/lib/localization';
// import { getVideoSupportAssetUrl } from 'components/ui/video-player/player-view-controller';
import { AppFactory } from '@app/app-factory';

const poster = 'listening-warmup-poster.svg';

//storage.googleapis.com/jw-pub/spa/video/listening-warmup-poster.png

// for testing if enable from dev tools
const shortVideoId = 'b454360a9a662a8894aef7ae7ccf8df1';

const en = {
  videoId: '806fd2e95cfbea9e11706f3754124948',
  poster,
};

const pt = {
  videoId: '3495e7251aa0f1aa2842057655d12ee1',
  poster,
};

const es = {
  videoId: '806fd2e95cfbea9e11706f3754124948', // same as english for now
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}
